import Vue from "vue";
import Router from "vue-router";
import {Notification} from "element-ui";

Vue.use(Router);

const viewport = {
  content: "width=device-width, initial-scale=1.0, user-scalable=no",
};

const router = new Router({
  //mode: 'history',
  //base: __dirname,
  routes: [
    {
      path: "/",
      redirect: "/index",
    },
    {
      path: "/login",
      name: "login",
      component: () => import("@/views/Login.vue"),
      meta: { title: "登录", viewport: viewport },
    },
    {
      path: "/miniLogin",
      name: "miniLogin",
      component: () => import("@/views/workspace_mini/MiniLogin.vue"),
      meta: { title: "登录", viewport: viewport },
    },
    {
      path: "/index",
      name: "index",
      component: () => import("@/views/Index.vue"),
      meta: { title: "OA工作流", viewport: viewport },
    },
    {
      path: "/workspace",
      name: "workspace",
      component: () => import("@/views/admin/home/home.vue"),
      meta: { title: "工作区", viewport: viewport },
      redirect: '/workspace/home',
      children: [
        {
          path: "formsPanel",
          name: "formsPanel",
          component: () => import("@/views/admin/FormsPanel.vue"),
          meta: { title: "表单管理", viewport: viewport },
        },
        {
          path: "home",
          name: 'home',
          component: () => import('@/views/admin/home/home.vue'),
          meta: { title: "审批列表", viewport: viewport },
        },
        {
          path: "approval",
          name: 'approval',
          component: () => import('@/views/workspace/approval/index.vue'),
          meta: { title: "审批列表", viewport: viewport },
        },
        {
          path: "todo",
          name: 'todo',
          component: () => import('@/views/workspace/todo/index.vue'),
          meta: { title: "待我处理", viewport: viewport },
        },
        {
          path: "apply",
          name: 'apply',
          component: () => import('@/views/workspace/apply/index.vue'),
          meta: { title: "我发起的", viewport: viewport },
        },
        {
          path: "about",
          name: 'about',
          component: () => import('@/views/workspace/about/index.vue'),
          meta: { title: "关于我的", viewport: viewport },
        },

        {
          path: "cc",
          name: 'cc',
          component: () => import('@/views/workspace/cc/index.vue'),
          meta: { title: "抄送我的", viewport: viewport },
        },
        {
          path: "submit",
          name: 'submit',
          component: () => import('@/views/workspace/submit/index.vue'),
          meta: { title: "数据管理", viewport: viewport },
        }
      ]
    },
    /**
     * 手机路由配置开始
     */
    {
      path: "/workspaceMini",
      name: "home",
      component: () => import("@/views/workspace_mini/home.vue"),
      meta: { title: "工作区", viewport: viewport,keepAlive: true },
      redirect: '/workspaceMini/approval',
      children: [
        {
          path: "approval",
          name: 'approval',
          component: () => import('@/views/workspace_mini/approval/index.vue'),
          meta: { title: "申请中心", viewport: viewport },
        },
        {
          path: "my",
          name: 'my',
          component: () => import('@/views/workspace_mini/my/index.vue'),
          meta: { title: "我的", viewport: viewport },
        },
        {
          path: "approver",
          name: 'approver',
          component: () => import('@/views/workspace_mini/index.vue'),
          meta: { title: "审批中心", viewport: viewport },
        },
        {
          path: "todo",
          name: 'todo',
          component: () => import('@/views/workspace_mini/todo/index.vue'),
          meta: { title: "待处理的", viewport: viewport },
        },
        {
          path: "apply",
          name: 'apply',
          component: () => import('@/views/workspace_mini/apply/index.vue'),
          meta: { title: "我发起的", viewport: viewport },
        },
        {
          path: "about",
          name: 'about',
          component: () => import('@/views/workspace_mini/about/index.vue'),
          meta: { title: "关于我的", viewport: viewport },
        },
        {
          path: "cc",
          name: 'cc',
          component: () => import('@/views/workspace_mini/cc/index.vue'),
          meta: { title: "抄送我的", viewport: viewport },
        },
      ]
    },
    /**
     * 手机路由配置结束
     */
    {
      path: "/workspaceMini/applyFlow",
      name: "applyFlow",
      component: () => import('@/views/workspace_mini/approval/startApply.vue'),
      meta: { title: "申请", viewport: viewport },
    },
    {
      path: "/workspaceMini/process/instance/tabs",
      name: "detailTabs",
      component: () =>
          import("@/views/workspace_mini/process/ProcessInstanceTabs.vue"),
      meta: { title: "流程详情", viewport: viewport },
    },
    {
      path: "/workspaceMini/process/instance/tabs/processDiagramViewer",
      name: "processDiagramViewer",
      component: () =>
          import("@/views/admin/layout/ProcessDiagramViewer.vue"),
      meta: { title: "节点详情", viewport: viewport },
    },
    {
      path: "/workspace/process/instance/tabs",
      name: "processInstanceTabs",
      component: () =>
        import("@/views/workspace/process/ProcessInstanceTabs.vue"),
      meta: { title: "流程详情", viewport: viewport },
    },
    /**
     * web管理后台 路由配置开始
     */
    {
      path: "/admin",
      name: "admin",
      component: () => import("@/views/admin/home/home.vue"),
      meta: { title: "OA管理系统", viewport: viewport },
      children: [
        {
          path: "contacts",
          name: "contacts",
          component: () => import("@/views/admin/contacts/Home.vue"),
          meta: { title: "通讯录", viewport: viewport },
          children:[
            {
              path: "user",
              name: "user",
              component: () => import("@/views/admin/contacts/user/User.vue"),
              meta: { title: "成员管理", viewport: viewport },
            },
            {
              path: "dept",
              name: "dept",
              component: () => import("@/views/admin/contacts/dept/Dept.vue"),
              meta: { title: "部门管理", viewport: viewport },
            },
            {
              path: "role",
              name: "role",
              component: () => import("@/views/admin/contacts/role/Role.vue"),
              meta: { title: "角色管理", viewport: viewport },
            },
          ]
        },
        {
          path: "attendance",
          name: "attendance",
          component: () => import("@/views/admin/contacts/Home.vue"),
          meta: { title: "考勤打卡", viewport: viewport },
          children:[
            {
              path: "scheduling",
              name: "scheduling",
              component: () => import("@/views/admin/attendance/scheduling/Scheduling.vue"),
              meta: { title: "排班表", viewport: viewport },
            },
            {
              path: "setScheduling",
              name: "setScheduling",
              component: () => import("@/views/admin/attendance/scheduling/SetScheduling.vue"),
              meta: { title: "排班表", viewport: viewport },
            },
            {
              path: "attendanceGroup",
              name: "attendanceGroup",
              component: () => import("@/views/admin/attendance/attendanceGroup/AttendanceGroup.vue"),
              meta: { title: "考勤组", viewport: viewport },
            },
            {
              path: "attendanceRules",
              name: "attendanceRules",
              component: () => import("@/views/admin/attendance/attendanceRules/Home.vue"),
              meta: { title: "考勤规则", viewport: viewport },
              children:[
                {
                  path: "repairCard",
                  name: "repairCard",
                  component: () => import("@/views/admin/attendance/attendanceRules/repairCard/RepairCard.vue"),
                  meta: { title: "补卡规则", viewport: viewport },
                },
              ]
            },
            {
              path: "classes",
              name: "classes",
              component: () => import("@/views/admin/attendance/classes/Classes.vue"),
              meta: { title: "班次管理", viewport: viewport },
            },
            {
              path: "holiday",
              name: "holiday",
              component: () => import("@/views/admin/attendance/holiday/Holiday.vue"),
              meta: { title: "假期管理", viewport: viewport },
            },
          ]
        },
        {
          path: "form",
          name: "form",
          component: () => import("@/views/admin/contacts/Home.vue"),
          meta: { title: "OA管理", viewport: viewport },
          children:[
            {
              path: "formsPanel",
              name: "formsPanel",
              component: () => import("@/views/admin/FormsPanel.vue"),
              meta: { title: "表单管理", viewport: viewport },
            },
            {
              path: "pastRecords",
              name: "pastRecords",
              component: () => import("@/views/admin/DingDingPastRecords.vue"),
              meta: { title: "钉钉历史表单", viewport: viewport },
            },
          ]
        },
        {
          path: "formSetting",
          name: "formSetting",
          component: () => import("@/views/admin/layout/FormDesign.vue"),
          meta: {title: '表单设计'}
        }, {
          path: "processDesign",
          name: "processDesign",
          component: () => import("@/views/admin/layout/ProcessDesign.vue"),
          meta: {title: '流程设计'}
        }, {
          path: "proSetting",
          name: "proSetting",
          component: () => import("@/views/admin/layout/FormProSetting.vue"),
          meta: {title: '高级设置'}
        }
      ]
    },
    /**
     * web管理后台 路由配置结束
     */
    {
      path: "/admin/design",
      name: "design",
      component: () => import("@/views/admin/FormProcessDesign.vue"),
      meta: { title: "表单流程设计", viewport: viewport },
         children: [
        {
          path: "baseSetting",
          name: "baseSetting",
          component: () => import("@/views/admin/layout/FormBaseSetting.vue"),
          meta: {title: '基础设置'}
        }, {
          path: "formSetting",
          name: "formSetting",
          component: () => import("@/views/admin/layout/FormDesign.vue"),
          meta: {title: '表单设计'}
        }, {
          path: "processDesign",
          name: "processDesign",
          component: () => import("@/views/admin/layout/ProcessDesign.vue"),
          meta: {title: '流程设计'}
        }, {
          path: "proSetting",
          name: "proSetting",
          component: () => import("@/views/admin/layout/FormProSetting.vue"),
          meta: {title: '高级设置'}
        }
      ]
    },
  ],
});

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  if (to.meta.content) {
    const head = document.getElementByTagName("head");
    const meta = document.createElement("meta");
    meta.name = "viewport";
    meta.content = "width=device-width, initial-scale=1.0, user-scalable=no";
    head[0].appendChild(meta);
  }
  localStorage.setItem("router-path", to.path);
  let urlData = getUrlData();
  if (to.path == '/miniLogin' && urlData!=null && urlData.type && urlData.type == "mini"){
      next();return;
  }else {
    let  user = JSON.parse(localStorage.getItem("user"));
    if ((user == null || user.token == null) && to.path != '/login') {
      next({ name: 'login' });return;
    }
  }
  next();

});
  /**
   * 解析url中的参数值
   */
  function getUrlData(){
    let url = window.location.href;
    if (url==null || url == ""){
      return null;
    }


    let data = url.slice(url.indexOf('?') + 1);
    let pairs = data.split('&');
    let params = {};
    for (let i = 0; i < pairs.length; i++) {
      let pair = pairs[i].split('=');
      let key = decodeURIComponent(pair[0]);
      let value = decodeURIComponent(pair[1]);
      params[key] = value;
    }
    return params;
  }
export default router;
